import useTaskUrl from 'app/hooks/use-task-url';
import { TEmployeeTaskFormatted, TEmployeeTaskItem } from 'models/employee/task.model';
import { useEffect, useRef } from 'react';

import { Typography } from '@mui/material';

import TaskSidebarItem from './item';
import TaskSidebarSectionHeader from './section-header';

type Props = {
  item: TEmployeeTaskFormatted;
  selectedList?: TEmployeeTaskItem | null;
  isFacility?: boolean;
  onSelectList?: (list: TEmployeeTaskItem, isRefetchLists?: boolean) => void;
};

function TaskSidebarSection({ item, selectedList, onSelectList, isFacility = false }: Props) {
  const isEmptyTask = !item?.records?.length;
  const { listId } = useTaskUrl();
  const isSelectSidebarItem = useRef(false);

  // effect to set selected list after reload page or from external page, e.g. from calendar
  useEffect(() => {
    if (!listId || isSelectSidebarItem.current) return;
    const listSelected = item?.records?.find((list: TEmployeeTaskItem) =>
      listId === 'todo' ? list.id === listId : list.id === Number(listId),
    );

    if (!listSelected) return;
    onSelectList?.(listSelected, false);
    isSelectSidebarItem.current = true;
  }, [listId, onSelectList, item?.records]);

  const handleSelectList = (list: TEmployeeTaskItem) => () => {
    isSelectSidebarItem.current = true;
    onSelectList?.(list, true);
  };

  return (
    <div className="mb-16 rounded-8 ">
      <TaskSidebarSectionHeader
        taskGroup={item.taskGroup}
        total={item.total}
      />
      <div className="border-gray-200 rounded-b-8 border-1">
        {item?.records?.map((list: any) => (
          <TaskSidebarItem
            key={list.id}
            item={list}
            taskGroup={item.taskGroup}
            isActive={selectedList?.id === list?.id}
            onSelectTask={handleSelectList(list)}
            isFacility={isFacility}
          />
        ))}

        {isEmptyTask && (
          <div className="p-16">
            <Typography className="text-12 text-secondaryLight">No tasks</Typography>
          </div>
        )}
      </div>
    </div>
  );
}

export default TaskSidebarSection;
