import DataSelect from 'app/components/cores/data-select';
import { InputNumber } from 'app/components/cores/inputs/input-number';
import clsx from 'clsx';
import {
  DEFAULT_START_AFTER,
  DEFAULT_START_TIME,
  METRIC_OPTIONS,
  POSITION_OPTIONS,
  TIME_FORMAT,
} from 'constants/index';
import { formatTimeValue } from 'domains/display-time.domain';
import _ from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Control, useController } from 'react-hook-form';
import { toNumber } from 'utils/number';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

const useStyles = makeStyles((theme: any) => ({
  startTime: {},
  layout: {
    '& ul': {
      flex: 1,
    },
    '& li': {
      flex: 1,
    },
  },
  button: {
    backgroundColor: `${theme.palette.background.paper} !important`,
  },
}));

type Props = {
  name: string;
  control: Control<any>;
  onChange?: (nextTime: any) => void;
  isUseOpeningTime?: boolean;
};

function StartTime({ name, control, onChange, isUseOpeningTime = true }: Props) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [metric, setMetric] = useState('60');
  const [position, setPosition] = useState('after');

  const isSetMetric = useRef(false);

  // time
  const {
    field: { value: time, onChange: onTimeChange },
  } = useController({
    name: `${name}.time`,
    control,
  });

  // start after
  const {
    field: { value: startAfter, onChange: onStartAfterChange },
  } = useController({
    name: `${name}.startAfter`,
    control,
    defaultValue: null,
    disabled: !isUseOpeningTime,
  });

  // start after closing
  const {
    field: { value: startAfterClosing, onChange: onStartAfterClosingChange },
  } = useController({
    name: `${name}.startAfterClosing`,
    control,
    defaultValue: null,
    disabled: !isUseOpeningTime,
  });

  // count
  const {
    field: { value: count, onChange: onCountChange },
  } = useController({
    name: `${name}.count`,
    control,
  });

  // used for old data
  const formattedValue = useMemo(() => formatTimeValue(time), [time]);

  const isUseStartTime = useMemo(() => {
    if (!isUseOpeningTime) return false;
    return !_.isNumber(toNumber(time)) && !_.isNumber(toNumber(startAfterClosing));
  }, [time, startAfterClosing, isUseOpeningTime]);

  const isUseEndTime = useMemo(() => {
    if (!isUseOpeningTime) return false;
    return !_.isNumber(toNumber(time)) && !_.isNumber(toNumber(startAfter));
  }, [time, startAfter, isUseOpeningTime]);

  const handleUseStartTime = useCallback(
    (__: any, checked: boolean) => {
      if (checked) {
        onTimeChange?.(null);
        onChange?.(null);
        onStartAfterChange(DEFAULT_START_AFTER);
        onStartAfterClosingChange(null);
        closePicker();
      } else {
        onTimeChange?.(DEFAULT_START_TIME);
        onChange?.(DEFAULT_START_TIME);
        onStartAfterChange(null);
      }
    },
    [onChange, onTimeChange, onStartAfterChange, onStartAfterClosingChange],
  );

  const handleUseEndTime = useCallback(
    (__: any, checked: boolean) => {
      if (checked) {
        onTimeChange?.(null);
        onChange?.(null);
        onStartAfterChange(null);
        onStartAfterClosingChange(DEFAULT_START_AFTER);
        onCountChange(count < 0 ? 1 : count);
        closePicker();
      } else {
        onTimeChange?.(DEFAULT_START_TIME);
        onChange?.(DEFAULT_START_TIME);
        onStartAfterClosingChange(null);
      }
    },
    [count, onChange, onTimeChange, onStartAfterChange, onStartAfterClosingChange, onCountChange],
  );

  const ActionBar = useCallback(() => {
    return (
      <div className="absolute bottom-0 w-full px-12 py-4">
        <FormControlLabel
          label="Use Start Time"
          control={<Checkbox checked={isUseStartTime} />}
          classes={{ label: 'text-12' }}
          onChange={handleUseStartTime}
        />
        <FormControlLabel
          label="Use End Time"
          control={<Checkbox checked={isUseEndTime} />}
          classes={{ label: 'text-12' }}
          onChange={handleUseEndTime}
        />
      </div>
    );
  }, [isUseStartTime, isUseEndTime, handleUseStartTime, handleUseEndTime]);

  // effect to set position
  useEffect(() => {
    if (isUseEndTime) {
      if (!startAfterClosing) return;
      setPosition(startAfterClosing < 0 ? 'before' : 'after');
    }

    if (isUseStartTime) {
      if (!startAfter) return;
      setPosition(startAfter < 0 ? 'before' : 'after');
    }
  }, [startAfter, startAfterClosing, isUseEndTime, isUseStartTime]);

  // effect to set metric
  useEffect(() => {
    if (isUseEndTime) {
      if (!startAfterClosing || isSetMetric.current) return;
      isSetMetric.current = true;
      setMetric(startAfter % 3600 === 0 ? '3600' : '60');
    }

    if (isUseStartTime) {
      if (!startAfter || isSetMetric.current) return;
      isSetMetric.current = true;
      setMetric(startAfter % 3600 === 0 ? '3600' : '60');
    }
  }, [startAfter, startAfterClosing, isUseEndTime, isUseStartTime]);

  const openPicker = () => {
    setIsOpen(true);
  };

  const closePicker = () => {
    setIsOpen(false);
  };

  const handleTimeChange = (value: any) => {
    const nextTime = moment(value).format(TIME_FORMAT);
    onTimeChange?.(nextTime);
    onChange?.(nextTime);
    onStartAfterChange(null);
    onStartAfterClosingChange(null);
  };

  const handleStarAfterChange = (value?: number) => {
    const nextStartAfter = Number(value) * Number(metric);
    updateStartAfter(position, nextStartAfter);
  };

  const handleStarAfterClosingChange = (value?: number) => {
    const nextStartAfterClosing = Number(value) * Number(metric);
    updateStartAfterClosing(position, nextStartAfterClosing);
  };

  const handleStartAfterMetricChange = (value: string) => {
    const originalStartAfter = startAfter / Number(metric);
    const nextStartAfter = originalStartAfter * Number(value);
    setMetric(value);
    onStartAfterChange(nextStartAfter);
  };

  const handleStartAfterClosingMetricChange = (value: string) => {
    const originalStartAfterClosing = startAfterClosing / Number(metric);
    const nextStartAfterClosing = originalStartAfterClosing * Number(value);
    setMetric(value);
    onStartAfterClosingChange(nextStartAfterClosing);
  };

  const handleStartAfterPositionChange = (value: string) => {
    setPosition(value);
    updateStartAfter(value, startAfter);
  };

  const handleStartAfterClosingPositionChange = (value: string) => {
    setPosition(value);
    updateStartAfterClosing(value, startAfterClosing);
  };

  const updateStartAfter = (changedPosition: string, changedStartAfter: number) => {
    const nextStartAfter = Math.abs(changedStartAfter);
    if (changedPosition === 'after') {
      onStartAfterChange(nextStartAfter);
    } else {
      onStartAfterChange(-nextStartAfter);
    }
  };

  const updateStartAfterClosing = (changedPosition: string, changedStartAfterClosing: number) => {
    const nextStartAfterClosing = Math.abs(changedStartAfterClosing);
    if (changedPosition === 'after') {
      onStartAfterClosingChange(nextStartAfterClosing);
    } else {
      onStartAfterClosingChange(-nextStartAfterClosing);
    }
  };

  return (
    <div className="flex flex-wrap flex-1 gap-x-8 item-row gap-y-6">
      <div className={clsx('relative', classes.startTime)}>
        <TimePicker
          open={isOpen}
          ampm={false}
          timeSteps={{ minutes: 1 }}
          value={formattedValue}
          className="min-w-128 max-w-160"
          format={TIME_FORMAT}
          views={['hours', 'minutes']}
          slots={
            isUseOpeningTime
              ? {
                  actionBar: ActionBar,
                }
              : {}
          }
          slotProps={{
            layout: {
              classes: {
                contentWrapper: clsx('w-160', isUseOpeningTime && 'mb-96', classes.layout),
              },
            },
            textField: {
              size: 'small',
              variant: 'filled',
              InputProps: {
                disableUnderline: true,
                className: 'bg-paper rounded-4',
                classes: {
                  input: 'py-10 rounded-4 bg-paper text-13',
                },
              },
            },
          }}
          onOpen={openPicker}
          onClose={closePicker}
          onAccept={closePicker}
          onChange={handleTimeChange}
        />
        {(isUseStartTime || isUseEndTime) && (
          <Button
            disableElevation={true}
            disableFocusRipple={true}
            disableRipple={true}
            disableTouchRipple={true}
            className={clsx(
              'absolute inset-0 z-10 flex items-center justify-between w-full h-40 capitalize text-13 font-400 text-secondaryMain',
              classes.button,
            )}
            onClick={openPicker}
          >
            <Typography className="pl-4 text-13">{isUseStartTime ? 'Start Time' : 'End Time'}</Typography>
            <ArrowDropDownIcon className="-mr-6 text-secondaryLight" />
          </Button>
        )}
      </div>

      {isUseStartTime && (
        <>
          <div className="w-104">
            <DataSelect
              disabled={!!formattedValue}
              options={POSITION_OPTIONS}
              value={position}
              onChange={handleStartAfterPositionChange}
            />
          </div>
          <InputNumber
            value={startAfter / Number(metric)}
            variant="filled"
            className="w-72"
            InputProps={{
              disableUnderline: true,
              className: 'bg-paper rounded-4',
              classes: {
                input: 'pt-12 pb-10 rounded-4 bg-paper text-13',
              },
            }}
            allowNegative={false}
            decimalScale={0}
            disabled={!!formattedValue}
            onChange={handleStarAfterChange}
          />
          <div className="w-104">
            <DataSelect
              disabled={!!formattedValue}
              options={METRIC_OPTIONS}
              value={metric}
              onChange={handleStartAfterMetricChange}
            />
          </div>
        </>
      )}

      {isUseEndTime && (
        <>
          <div className="w-104">
            <DataSelect
              disabled={!!formattedValue}
              options={POSITION_OPTIONS}
              value={position}
              onChange={handleStartAfterClosingPositionChange}
            />
          </div>
          <InputNumber
            value={startAfterClosing / Number(metric)}
            variant="filled"
            className="w-72"
            InputProps={{
              disableUnderline: true,
              className: 'bg-paper rounded-4',
              classes: {
                input: 'py-10 rounded-4 bg-paper',
              },
            }}
            allowNegative={false}
            decimalScale={0}
            disabled={!!formattedValue}
            onChange={handleStarAfterClosingChange}
          />
          <div className="w-104">
            <DataSelect
              disabled={!!formattedValue}
              options={METRIC_OPTIONS}
              value={metric}
              onChange={handleStartAfterClosingMetricChange}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default StartTime;
